import React from 'react';
import './Footer.css';
import {urlWithUtms} from '../utils/urlWithUtms'
import ShareLinks from '../components/Share/ShareLinks';



export default () => (
    <div>
        <div className="taCenter share">
            <ShareLinks/>
        </div>
        <br />
        <footer className="footer">
            <div className="container taCenter top">
                <a href={urlWithUtms('https://www.getweave.com')} target="_blank">Why Weave?</a>
            </div>
            <div className="container taCenter bottom">
                <span>
                    Copyright © {new Date().getFullYear()}{' '}
                    <a href={urlWithUtms('https://www.getweave.com')} target="_blank">Weave Communications</a>
                    . All rights reserved.{' '}
                </span>
            </div>
        </footer>
    </div>
);
