import React, { Component } from 'react'
import { graphql } from 'gatsby'
import Helmet from 'react-helmet'

export const query = graphql`
  fragment Meta on MarkdownRemark {
    frontmatter {
      meta {
        title
        description
        noindex
        canonicalLink
      }
    }
  }
`
// Kent ToDo
export default class Meta extends Component {
  render() {
    const {
      title,
      url,
      description,
      absoluteImageUrl = '',
      twitterSiteAccount,
      twitterCreatorAccount,
      noindex,
      canonicalLink,
      siteTitle,
      siteDescription,
      googleTrackingId,
      marketoId,
      // overwrite { title, description } if in fields or fields.meta
    } = this.props

    return (
      <Helmet>
        {title && <title>{title}</title>}
        {title && <meta property="og:title" content={title} />}
        {description && <meta name="description" content={description} />}
        {description && (
          <meta property="og:description" content={description} />
        )}
        {url && <meta property="og:type" content="website" />}
        {url && <meta property="og:url" content={url} />}
        {noindex && <meta name="robots" content="noindex" />}
        {canonicalLink && <link rel="canonical" href={canonicalLink} />}

        <meta property="og:locale" content="en_US" />
        <meta property="og:site_name" content="Join your fellow Entrepreneurs, Creators, Innovators, First Timers, Boundary Pushers and explore the stories that connect us all as." />
        {/* <meta property="og:image:secure_url" content="../images/social-share.jpg" /> */}
        <meta property="og:image" content="../images/social-share.jpg" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@getweave" />
        <meta name="twitter:title" content="Join Us!" />
        <meta name="twitter:description" content="Join your fellow Entrepreneurs, Creators, Innovators, First Timers, Boundary Pushers and explore the stories that connect us all as." />
        <meta name="twitter:image" content="../images/social-share.jpg" />

        {googleTrackingId && (
          <script
            async
            src={`https://www.googletagmanager.com/gtag/js?id=${googleTrackingId}`}
          />
        )}

        {googleTrackingId && (
          <script>
            {`
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());
              gtag('config', '${googleTrackingId}');
            `}
          </script>
        )}
        {marketoId && (
          <script>
            {`
              (function() {
            		var didInit = false;
            		function initMunchkin() {
            			if(didInit === false) {
            				didInit = true;
            				Munchkin.init('${marketoId}');
            			}
            		}
            		var s = document.createElement('script');
            		s.type = 'text/javascript';
            		s.async = true;
            		s.src = '//munchkin.marketo.net/munchkin.js';
            		s.onreadystatechange = function() {
            			if (this.readyState == 'complete' || this.readyState == 'loaded') {
            				initMunchkin();
            			}
            		};
            		s.onload = initMunchkin;
            		document.getElementsByTagName('head')[0].appendChild(s);
            	})();
            `}
          </script>
        )}

      </Helmet>
    )
  }
}
