import React, { Component } from 'react';
import { Location } from '@reach/router';
import { Link } from 'gatsby';
import { /*Menu, */ X } from 'react-feather'; //icons. We are using a unique Menu icon.
import Logo from './Logo';

import './Nav.css';
// Kent ToDo
export class Navigation extends Component {
    state = {
        active: false,
        activeSubNav: false,
        currentPath: false,
    };

    componentDidMount = () =>
        this.setState({ currentPath: this.props.location.pathname });

    handleMenuToggle = () => this.setState({ active: !this.state.active });

    // Only close nav if it is open
    handleLinkClick = () => this.state.active && this.handleMenuToggle();

    toggleSubNav = (subNav) =>
        this.setState({
            activeSubNav: this.state.activeSubNav === subNav ? false : subNav,
        });

    render() {
        const { active } = this.state,
            { subNav } = this.props,
            NavLink = ({ to, className, children, ...props }) => (
                <Link
                    to={to}
                    className={`NavLink ${to === this.state.currentPath ? 'active' : ''
                        } ${className}`}
                    onClick={this.handleLinkClick}
                    {...props}
                >
                    {children}
                </Link>
            );

        return (
            <nav className={`Nav ${active ? 'Nav-active' : ''}`}>
                <div className="Nav--Container container">
                    <Link to="/" onClick={this.handleLinkClick} className="Nav--Logo">
                        <Logo />
                    </Link>
                    <div className="Nav--Links">
                        <NavLink to="/">Home</NavLink>
                        <NavLink to="/entrepreneurs/">Entrepreneurs</NavLink>
                        {/* <div
                            className={`Nav--Group ${this.state.activeSubNav === 'posts'
                                ? 'active'
                                : ''
                                }`}
                        >
                            <span
                                className={`NavLink Nav--GroupParent ${this.props.location.pathname.includes(
                                    'posts'
                                ) ||
                                    this.props.location.pathname.includes(
                                        'blog'
                                    ) ||
                                    this.props.location.pathname.includes(
                                        'post-categories'
                                    )
                                    ? 'active'
                                    : ''
                                    }`}
                                onClick={() => this.toggleSubNav('posts')}
                            >
                                Blog
                                <div className="Nav--GroupLinks">
                                    <NavLink
                                        to="/blog/"
                                        className="Nav--GroupLink"
                                    >
                                        All Posts
                                    </NavLink>
                                    {subNav.posts.map((link, index) => (
                                        <NavLink
                                            to={link.slug}
                                            key={'posts-subnav-link-' + index}
                                            className="Nav--GroupLink"
                                        >
                                            {link.title}
                                        </NavLink>
                                    ))}
                                </div>
                            </span>
                        </div> */}
                        <NavLink to="/contact/">Contact</NavLink>
                        {/* <NavLink to="/challenge/">Challenge</NavLink> */}
                        {/* <NavLink to="/components/">Components</NavLink> */}
                    </div>
                    <button
                        className="Button-blank Nav--MenuButton"
                        onClick={this.handleMenuToggle}
                    >
                        {active ? <X /> : <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><line x1="3" y1="12" x2="21" y2="12"></line><line x1="3" y1="6" x2="21" y2="6"></line><line x1="3" y1="18" x2="11" y2="18"></line></svg>}
                    </button>
                </div>
            </nav>
        );
    }
}

export default ({ subNav }) => (
    <Location>{(route) => <Navigation subNav={subNav} {...route} />}</Location>
);
