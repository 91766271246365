import React from 'react';

export default () => (

        <div className="taCenter share">
            <h5>Help others learn and grow too! <br />SHARE</h5>

            {/* Facebook */}
            <p className="mr">
                <a  href={`https://www.facebook.com/sharer/sharer.php?u=https://weaveconnected.com/`}
        target="_blank" rel="noreferrer">
                    <img src="/images/facebook.svg" alt=""/>
                </a>
            </p>
            
            {/* twitter */}
            <p className="mr">
                <a href={`https://twitter.com/intent/tweet?text=Join your fellow Entrepreneurs, Creators, Innovators, First Timers, Boundary Pushers and explore the stories that connect us all as.`} target="_blank" rel="noreferrer">
                    <img src="/images/twitter.svg" alt=""/>
                </a>
            </p>

            {/* instagram */}
            {/* <p className="mr">
                <a href="https://www.instagram.com/getweave/" target="_blank" rel="noreferrer">
                    <img src="/images/instagram.svg" alt=""/>
                </a>
            </p> */}

            {/* linkedin */}
            <p>
                <a  href={`https://www.linkedin.com/shareArticle?mini=true&url=https://weaveconnected.com/&title=Join your fellow Entrepreneurs, Creators, Innovators, First Timers, Boundary Pushers and explore the stories that connect us all as.&source=https://weaveconnected.com/`}
        target="_blank" rel="noreferrer">
                    <img src="/images/linkedin.svg" alt=""/>
                </a>
            </p>
        </div>

);